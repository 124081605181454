import {AuthModel} from './_models'
// import {useAuth} from '../core/Auth'
// import {refreshToken} from '../core/_requests'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  // axios.interceptors.response.use((response) => {
  //   return response
  // }, async function (error) {
  //   const originalRequest = error.config;
  //   if (error.response.status === 403 && !originalRequest._retry) {
  //     originalRequest._retry = true;
  //     const auth = getAuth()
  //     if (auth && auth.token) {
  //       const {data: refresh_auth} = await refreshToken(auth.refresh_token)
  //       setAuth(refresh_auth.data)
  //     }

  //     return axios(originalRequest);
  //   }
  //   return Promise.reject(error);
  // });
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
