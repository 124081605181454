import axios from 'axios'
import {AuthModel, UserModel} from './_models'
// import {Response} from '../../../../_metronic/helpers'

interface ApiResponse<T> {
  success: boolean
  data: T
}

const API_URL = process.env.REACT_APP_API_GATEWAY
const url_sementara = `http://127.0.0.1:8000/api/user-service/v1`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify-token`
export const REFRESH_TOKEN = `${API_URL}/refresh-token`

export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/update-password/:id`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<ApiResponse<AuthModel>>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export async function updatePassword(id, password) {
  const res = await axios
    .put(`${API_URL}/auth/update-password/${id}`, {password})
    .catch((error) => {
      return {data: {message: error.message}, status: error.response.status}
    })

  return res
}

export function getUserByToken(token: string) {
  return axios
    .post<ApiResponse<UserModel>>(GET_USER_BY_ACCESSTOKEN_URL, {
      token: token,
    })
    .then((response) => {
      // Simpan data pengguna ke localStorage setelah mendapatkan respons
      localStorage.setItem('user_data', JSON.stringify(response.data.data.user_data))
      return response.data // Mengembalikan data respons untuk digunakan di komponen lain jika diperlukan
    })
    .catch((error) => {
      // Tangani kesalahan sesuai kebutuhan aplikasi Anda
      console.error('Error fetching user data:', error)
      throw error // Re-throw error untuk menangani lebih lanjut di komponen pemanggil
    })
}

export function refreshToken(refresh_token: string) {
  return axios.post<ApiResponse<AuthModel>>(REFRESH_TOKEN, {
    refresh_token: refresh_token,
  })
}

export async function getUserById(id: string) {
  const res = await axios.get(`${API_URL}/auth/user/${id}`).catch((error) => {
    return {data: {message: error.message}, status: error.response.status}
  })

  return res
}
