/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import Select from 'react-select'
import axios from 'axios'

const initialValues = {
  firstname: '',
  whatsapp: '',
  email: '',
  alamat: '',
  tempat_lahir: '',
  tanggal_lahir: '',
  foto: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string().required('Nama lengkap tidak boleh kosong'),
  email: Yup.string().email('Wrong email format').required('Alamat email tidak boleh kosong'),
  whatsapp: Yup.string()
    .max(50, 'Maximum 11 atau 12 nomor')
    .required('Nomor whatsapp tidak boleh kosong'),
  alamat: Yup.string().required('Alamat tidak boleh kosong'),
  tempat_lahir: Yup.string().required('Tempat lahir tidak boleh kosong'),
  tanggal_lahir: Yup.string().required('Tanggal lahir tidak boleh kosong'),
  foto: Yup.string().required('Foto lahir tidak boleh kosong'),

  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const API_URL = `http://localhost:3000/api`
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await register(
          values.email,
          values.firstname,
          values.whatsapp,
          values.alamat,
          values.tempat_lahir
        )
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const [provinsi, setProvinsi] = useState<{value: string; label: string}[]>([])
  const [kota, setKota] = useState<{value: string; label: string}[]>([])
  const [kecamatan, setKecamatan] = useState<{value: string; label: string}[]>([])
  const [desa, setDesa] = useState<{value: string; label: string}[]>([])
  const [inputValue, setInputValue] = useState('')

  const status_jabatan = [
    {value: 'mitra', label: 'mitra'},
    {value: 'staff', label: 'staff'},
  ]

  const action = (param) => {
    console.log(param)
  }
  const fetchProvinsi = () => {
    axios
      .get(`${API_URL}/users/get-provinsi`)
      .then((response) => {
        const newData = response.data.data.map((item) => {
          return {
            value: item.code,
            label: item.name,
          }
        })
        setProvinsi((prevState) => [...prevState, ...newData])
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const fetchKota = (param) => {
    setKota([])
    setKecamatan([])
    setDesa([])
    axios
      .get(`${API_URL}/users/get-kota/${param.value}`)
      .then((response) => {
        const newData = response.data.data.map((item) => {
          return {
            value: item.code,
            label: item.name,
          }
        })
        setKota((prevState) => [...prevState, ...newData])
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const fetchKecamatan = (param) => {
    setKecamatan([])
    setDesa([])
    axios
      .get(`${API_URL}/users/get-kecamatan/${param.value}`)
      .then((response) => {
        const newData = response.data.data.map((item) => {
          return {
            value: item.code,
            label: item.name,
          }
        })
        setKecamatan((prevState) => [...prevState, ...newData])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const fetchDesa = (param) => {
    setDesa([])
    axios
      .get(`${API_URL}/users/get-desa/${param.value}`)
      .then((response) => {
        const newData = response.data.data.map((item) => {
          return {
            value: item.code,
            label: item.name,
          }
        })
        setDesa((prevState) => [...prevState, ...newData])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
    console.log(e)
  }
  const submit = () => {
    setLoading(true)
  }
  useEffect(() => {
    PasswordMeterComponent.bootstrap()

    fetchProvinsi()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>STAFF BARU</h1>
        {/* end::Title */}

        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* end::Heading */}

      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Jago Bahasa</span>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Nama lengkap</label>
        <input
          placeholder='Nama lengkap'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          value={inputValue}
          onChange={(e) => handleInputChange(e)}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-8'>
        {/* begin::Form group whatsapp */}
        <label className='form-label fw-bolder text-dark fs-6'>Nomor whatsapp</label>
        <input
          placeholder='Nomor whatsapp'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('whatsapp')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.whatsapp && formik.errors.whatsapp,
            },
            {
              'is-valid': formik.touched.whatsapp && !formik.errors.whatsapp,
            }
          )}
        />
        {formik.touched.whatsapp && formik.errors.whatsapp && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.whatsapp}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Alamat email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Provinsi */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Pilih provinsi</label>
        <div>
          <Select
            placeholder='Pilih provinsi'
            defaultValue={null}
            name='provinsi'
            options={provinsi}
            isSearchable={true}
            onChange={fetchKota}
          />
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Provinsi */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Pilih kota</label>
        <div>
          <Select
            placeholder='Pilih kota'
            defaultValue={null}
            name='kota'
            options={kota}
            isSearchable={true}
            onChange={fetchKecamatan}
          />
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Provinsi */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Pilih kecamatan</label>
        <div>
          <Select
            placeholder='Pilih kecamatan'
            defaultValue={null}
            name='kecamatan'
            options={kecamatan}
            isSearchable={true}
            onChange={fetchDesa}
          />
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Provinsi */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Pilih desa</label>
        <div>
          <Select
            placeholder='Pilih desa'
            defaultValue={null}
            name='desa'
            options={desa}
            isSearchable={true}
            onChange={action}
          />
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Alamat */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Alamat</label>
        <textarea
          placeholder='Alamat'
          rows={4}
          cols={150}
          autoComplete='off'
          {...formik.getFieldProps('alamat')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.alamat && formik.errors.alamat,
            },
            {
              'is-valid': formik.touched.alamat && !formik.errors.alamat,
            }
          )}
        />
        {formik.touched.alamat && formik.errors.alamat && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.alamat}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Tempat lahir */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Tempat lahir</label>
        <input
          placeholder='Tulis nama kota'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('tempat_lahir')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.tempat_lahir && formik.errors.tempat_lahir,
            },
            {
              'is-valid': formik.touched.tempat_lahir && !formik.errors.tempat_lahir,
            }
          )}
        />
        {formik.touched.tempat_lahir && formik.errors.tempat_lahir && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.tempat_lahir}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Tanggal lahir */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Tanggal lahir</label>
        <input
          placeholder='Tanggal lahir'
          type='date'
          autoComplete='off'
          {...formik.getFieldProps('tanggal_lahir')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.tanggal_lahir && formik.errors.tanggal_lahir,
            },
            {
              'is-valid': formik.touched.tanggal_lahir && !formik.errors.tanggal_lahir,
            }
          )}
        />
        {formik.touched.tanggal_lahir && formik.errors.tanggal_lahir && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.tanggal_lahir}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Deskripsi */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Deskripsi (Optional)</label>
        <textarea
          placeholder='Deskripsi'
          rows={4}
          cols={150}
          autoComplete='off'
          className='form-control bg-transparent'
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group Foto */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Foto staff</label>
        <input
          placeholder='Tanggal lahir'
          type='file'
          autoComplete='off'
          {...formik.getFieldProps('foto')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.foto && formik.errors.foto,
            },
            {
              'is-valid': formik.touched.foto && !formik.errors.foto,
            }
          )}
        />
        {formik.touched.foto && formik.errors.foto && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.foto}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Provinsi */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Status</label>
        <div>
          <Select
            placeholder='Pilih status/jabatan'
            defaultValue={null}
            name='status_jabatan'
            options={status_jabatan}
            isSearchable={true}
            onChange={action}
          />
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the{' '}
            <a
              href='https://keenthemes.com/metronic/?page=faq'
              target='_blank'
              className='ms-1 link-primary'
            >
              Terms
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='button'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          onClick={submit}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/crafted/user-services/pengelolaan-staff'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
